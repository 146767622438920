<template>
  <div v-loading="loading">
    <el-tabs v-model="dataActive" type="border-card">
      <el-tab-pane label="年度经营/月度财务数据" name="yearManage">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span class="title_fw">年度经营型数据</span>
            <span class="title_right"
              ><SearchUserDate
                :type="'YearManage'"
                :title="'统计季度：'"
                :introductionType="'quarter'"
                :width="'100'"
                :clearable="true"
                :list="quarterGenerate"
                :yearValue="sectionInfo.yearManageYear"
                v-model="sectionInfo.yearManageQuarter"
                @change="onYearManageChange"
            /></span>
            <span class="title_right"
              ><SearchUserDate
                :title="titleYear"
                :type="'YearManage'"
                :introductionType="'quarter'"
                :width="'100'"
                :yearIsShow="true"
                :list="dictData.yearList"
                :twoValue="sectionInfo.yearManageQuarter"
                v-model="sectionInfo.yearManageYear"
                @change="onYearManageChange"
            /></span>
          </div>
          <el-table
            :summary-method="getSummaries"
            show-summary
            max-height="450"
            border
            :data="yearManageList"
          >
            <el-table-column
              prop="company"
              label="公司"
              align="center"
              fixed="left"
              min-width="100"
            >
              <template slot-scope="scope">
                {{ scope.row.company | dict(dictData.companyType) }}
              </template></el-table-column
            >
            <el-table-column prop="projectCount" min-width="80" label="立项数" align="center">
              <template slot-scope="scope">
                {{ scope.row.projectCount | applyAmount }}
              </template></el-table-column
            >
            <el-table-column prop="salesRevenue" min-width="120" label="销售额" align="center">
              <template slot-scope="{}" slot="header">
                <span>销售额</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>商务详情里的我方合同金额（销售合同）</div>
                  </div>
                </el-tooltip></template
              >
              <template slot-scope="scope">
                {{ scope.row.salesRevenue | applyAmount }}
              </template></el-table-column
            >
            <el-table-column prop="invoicedAmount" min-width="120" label="开票额" align="center">
              <template slot-scope="{}" slot="header">
                <span>开票额</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>商务所创建的项目，</div>
                    <div>项目收入中的已开票金额（销售合同）</div>
                  </div>
                </el-tooltip></template
              >
              <template slot-scope="scope">
                {{ scope.row.invoicedAmount | applyAmount }}
              </template></el-table-column
            >

            <el-table-column prop="receivedAmount" min-width="120" label="回款额" align="center">
              <template slot-scope="{}" slot="header">
                <span>回款额</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>商务所创建的项目，</div>
                    <div>项目收入中的已收款金额（销售合同）</div>
                  </div>
                </el-tooltip></template
              ><template slot-scope="scope">
                {{ scope.row.receivedAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="procurement" min-width="120" label="采购" align="center">
              <template slot-scope="{}" slot="header">
                <span>采购</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>商务详情里的我方合同金额带出（采购合同）</div>
                  </div>
                </el-tooltip></template
              >
              <template slot-scope="scope">
                {{ scope.row.procurement | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="receivedInvoices" min-width="120" label="收票" align="center">
              <template slot-scope="{}" slot="header">
                <span>收票</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>商务所创建的项目，</div>
                    <div>项目收入中的已开票金额（采购合同）</div>
                  </div>
                </el-tooltip></template
              >
              <template slot-scope="scope">
                {{ scope.row.receivedInvoices | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="payments" min-width="120" label="付款" align="center">
              <template slot-scope="{}" slot="header">
                <span>付款</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>商务所创建的项目，</div>
                    <div>项目收入中的已收款金额（销售合同）</div>
                  </div>
                </el-tooltip></template
              >
              <template slot-scope="scope">
                {{ scope.row.payments | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="businessExpend" min-width="130" label="商务报销" align="center">
              <template slot-scope="scope">
                {{ scope.row.businessExpend | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="projectExpend" min-width="130" label="项目报销" align="center">
              <template slot-scope="scope">
                {{ scope.row.projectExpend | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="publicSpendingExpend"
              min-width="130"
              label="公共报销"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.publicSpendingExpend | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="reimbursements" min-width="130" label="总报销" align="center">
              <template slot-scope="{}" slot="header">
                <span>总报销</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>商务报销+项目报销+公共报销</div>
                  </div>
                </el-tooltip></template
              >
              <template slot-scope="scope">
                {{ scope.row.reimbursements | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="actualReceipts" min-width="150" label="实际收款" align="center">
              <template slot-scope="{}" slot="header">
                <span>实际收款</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>项目收入中收款金额</div>
                  </div>
                </el-tooltip></template
              >
              <template slot-scope="scope">
                {{ scope.row.actualReceipts | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="actualExpenditure"
              min-width="150"
              label="实际支出"
              align="center"
            >
              <template slot-scope="{}" slot="header">
                <span>实际支出</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>费用报销 + 费用付款 + 人工成本 + 税费 + 外协生产费用 + 设备折旧</div>
                  </div>
                </el-tooltip></template
              >
              <template slot-scope="scope">
                {{ scope.row.actualExpenditure | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="fundBalance" min-width="150" label="资金余额" align="center">
              <template slot-scope="{}" slot="header">
                <span>资金余额</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>实际收款 - 实际支出</div>
                  </div>
                </el-tooltip></template
              >
              <template slot-scope="scope">
                {{ scope.row.fundBalance | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="totalRevenue" min-width="150" label="总收入" align="center">
              <template slot-scope="scope">
                {{ scope.row.totalRevenue | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="totalCost" min-width="150" label="总成本" align="center">
              <template slot-scope="scope">
                {{ scope.row.totalCost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="totalProfit" min-width="150" label="总利润" align="center">
              <template slot-scope="scope">
                {{ scope.row.totalProfit | applyAmount }}
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span class="title_fw">公司月度财务数据</span>
            <span class="title_right"
              ><SearchUserDate
                :type="'MonthFinance'"
                :introductionType="'month'"
                :width="'100'"
                :clearable="true"
                :list="monthGenerate"
                :yearValue="sectionInfo.monthFinanceYear"
                v-model="sectionInfo.monthFinanceMonth"
                @change="onMonthFinanceChange"
            /></span>
            <span class="title_right"
              ><SearchUserDate
                :title="titleYear"
                :width="'100'"
                :type="'MonthFinance'"
                :introductionType="'month'"
                :yearIsShow="true"
                :list="dictData.yearList"
                :twoValue="sectionInfo.monthFinanceMonth"
                v-model="sectionInfo.monthFinanceYear"
                @change="onMonthFinanceChange"
            /></span>
          </div>
          <el-table
            :summary-method="getMnthFinance"
            show-summary
            max-height="450"
            border
            :data="monthFinanceList"
          >
            <el-table-column
              prop="company"
              label="公司"
              align="center"
              fixed="left"
              min-width="100"
            >
              <template slot-scope="scope">
                {{ scope.row.company | dict(dictData.companyType) }}
              </template></el-table-column
            >
            <el-table-column prop="salesRevenue" min-width="130" align="center">
              <template slot-scope="{}" slot="header">
                <span>合同额（元）</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>商务详情里的我方合同金额</div>
                  </div>
                </el-tooltip></template
              >
              <template slot-scope="scope">
                {{ scope.row.salesRevenue | applyAmount }}
              </template></el-table-column
            >
            <el-table-column
              prop="receivedAmount"
              min-width="120"
              label="回款额（元）"
              align="center"
            >
              <template slot-scope="{}" slot="header">
                <span>回款额（元）</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>商务所创建的项目，</div>
                    <div>项目收入中的已收款金额</div>
                  </div>
                </el-tooltip></template
              ><template slot-scope="scope">
                {{ scope.row.receivedAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="invoicedAmount"
              min-width="120"
              label="应收额（元）"
              align="center"
            >
              <template slot-scope="{}" slot="header">
                <span>应收额（元）</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>商务所创建的项目，</div>
                    <div>项目收入中的已开票金额</div>
                  </div>
                </el-tooltip></template
              ><template slot-scope="scope">
                {{ scope.row.invoicedAmount | applyAmount }}
              </template>
            </el-table-column>

            <el-table-column prop="procurement" min-width="120" label="采购额（元）" align="center">
              <template slot-scope="{}" slot="header">
                <span>采购额（元）</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>商务详情里的我方合同金额带出（采购合同）</div>
                  </div>
                </el-tooltip></template
              >
              <template slot-scope="scope">
                {{ scope.row.procurement | applyAmount }}
              </template>
            </el-table-column>

            <el-table-column
              prop="businessExpend"
              min-width="130"
              label="商务报销（元）"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.businessExpend | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="projectExpend"
              min-width="130"
              label="项目报销（元）"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.projectExpend | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="publicSpendingExpend"
              min-width="130"
              label="公共报销（元）"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.publicSpendingExpend | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="reimbursements"
              min-width="130"
              label="报销额（元）"
              align="center"
            >
              <template slot-scope="{}" slot="header">
                <span>报销额（元）</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>商务报销+项目报销+公共报销</div>
                  </div>
                </el-tooltip></template
              >
              <template slot-scope="scope">
                {{ scope.row.reimbursements | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="receivedInvoices"
              min-width="120"
              label="应付额（元）"
              align="center"
            >
              <template slot-scope="{}" slot="header">
                <span>应付额（元）</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>按发起时间算起的所有报销 + 付款申请金额</div>
                  </div>
                </el-tooltip></template
              >
              <template slot-scope="scope">
                {{ scope.row.receivedInvoices | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="actualExpenditure"
              min-width="120"
              label="实际支出"
              align="center"
            >
              <template slot-scope="{}" slot="header">
                <span>实际支出</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>
                      费用报销+ 费用付款 + 人工支出（可能需要换成人工成本）+ 税费
                      +外协生产费用+设备折旧
                    </div>
                  </div>
                </el-tooltip></template
              >
              <template slot-scope="scope">
                {{ scope.row.actualExpenditure | applyAmount }}
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="季度商务/数据统计" name="statistics">
        <el-card class="box-card" style="margin-top: 8px">
          <div slot="header" class="clearfix">
            <span class="title_fw">季度新增商务数据表</span>
            <span class="title_right"
              ><SearchUserDate
                :title="titleYear"
                :type="'AddBusiness'"
                :width="'100'"
                :yearIsShow="true"
                :list="dictData.yearList"
                v-model="sectionInfo.addBusinessYear"
                @change="onAddBusinessChange"
            /></span>
          </div>
          <el-table max-height="450" border :data="addBusinessList">
            <el-table-column
              prop="quarters"
              label="季度"
              align="center"
              min-width="100"
            ></el-table-column>
            <el-table-column
              prop="newBusinessCount"
              min-width="130"
              label="新增商务数量"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.newBusinessCount | applyAmount }}
              </template></el-table-column
            >
            <el-table-column
              prop="newProjectCount"
              min-width="120"
              label="新增项目数量"
              align="center"
            >
              <template slot-scope="{}" slot="header">
                <span>新增项目数量</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>为新商务已创建的项目合计</div>
                  </div>
                </el-tooltip></template
              ><template slot-scope="scope">
                {{ scope.row.newProjectCount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="totalProjectAmount"
              min-width="120"
              label="新增项目总金额"
              align="center"
            >
              <template slot-scope="{}" slot="header">
                <span>新增项目总金额</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>为新商务已创建的项目，</div>
                    <div>项目收入合计（即公司应收合计）</div>
                  </div>
                </el-tooltip></template
              ><template slot-scope="scope">
                {{ scope.row.totalProjectAmount | applyAmount }}
              </template>
            </el-table-column>

            <el-table-column prop="growthRate" min-width="120" label="环比增长率" align="center">
              <template slot-scope="{}" slot="header">
                <span>环比增长率</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px"></i>
                  <div slot="content">
                    <div>指当前期与上一期相比的增长率。</div>
                    <div>（当前期数 - 上一期数） / 上一期数 * 100</div>
                  </div>
                </el-tooltip></template
              >
              <template slot-scope="scope"> {{ scope.row.growthRate }}% </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-row class="statistics_top">
          <el-card class="box-card" style="width: 33%">
            <div slot="header" class="clearfix">
              <span class="title_fw">项目类型分析</span>
              <span class="title_right"
                ><SearchUserDate
                  :title="titleYear"
                  :type="'ProjectType'"
                  :yearIsShow="true"
                  :list="dictData.yearList"
                  v-model="sectionInfo.projectTypeYear"
                  @change="onProjectTypeChange"
              /></span>
            </div>
            <ProjectType v-if="dataActive == 'statistics'" :projectTypeList="projectTypeList" />
          </el-card>
          <el-card class="box-card" style="width: 33%">
            <div slot="header" class="clearfix">
              <span class="title_fw">项目状态分析</span>
              <span class="title_right"
                ><SearchUserDate
                  :title="titleYear"
                  :type="'ProjectSource'"
                  :yearIsShow="true"
                  :list="dictData.yearList"
                  v-model="sectionInfo.projectStatusYear"
                  @change="onProjectSourceChange"
              /></span>
            </div>
            <ProjectStatus
              v-if="dataActive == 'statistics'"
              :projectStatusList="projectStatusList"
            />
          </el-card>
          <el-card class="box-card" style="width: 33%">
            <div slot="header" class="clearfix">
              <span class="title_fw">资金风险监控</span>
              <span class="title_right"
                ><SearchUserDate
                  :title="titleYear"
                  :type="'CapitalRisk'"
                  :yearIsShow="true"
                  :list="dictData.yearList"
                  v-model="sectionInfo.capitalRiskYear"
                  @change="onCapitalRiskChange"
              /></span>
            </div>
            <CapitalRisk
              v-if="dataActive == 'statistics'"
              :capitalRiskList="capitalRiskList"
            /> </el-card
        ></el-row>
      </el-tab-pane>

      <el-tab-pane label="公司收支趋势" name="companyMoney">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span class="title_right"
              ><SearchUserDate
                :title="titleYear"
                :type="'MoneyHistogram'"
                :yearIsShow="true"
                :list="dictData.yearList"
                v-model="sectionInfo.companyMoneyYear"
                @change="onMoneyHistogramChange"
            /></span>
          </div>
          <MoneyHistogram
            v-if="dataActive == 'companyMoney'"
            :companyMoneyList="companyMoneyList"
          />
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="项目利润趋势" name="projectProfit"> </el-tab-pane>
    </el-tabs>

    <!-- <el-row style="margin-top: 8px">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="title_fw">公司收支趋势图</span>
          <span class="title_right"
            ><SearchUserDate
              :title="titleYear"
              :type="'Histogram'"
              :introductionType="'staffId'"
              :yearIsShow="true"
              :list="dictData.yearList"
              :twoValue="sectionInfo.contractAmountPrincipal"
              v-model="sectionInfo.contractAmountYear"
              @change="onHistogramChange"
          /></span>
          <span class="title_right" v-if="isBoss"
            ><SearchUserDate
              :clearable="true"
              :filterable="true"
              :type="'Histogram'"
              :introductionType="'staffId'"
              :title="titlePrincipal"
              :list="userList"
              :yearValue="sectionInfo.contractAmountYear"
              v-model="sectionInfo.contractAmountPrincipal"
              @change="onHistogramChange"
          /></span>
        </div>
        <MoneyHistogram :contractAmountList="contractAmountList" />
      </el-card>
    </el-row>
    <el-row style="margin-top: 8px">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="title_fw">项目利润趋势图</span>
          <span class="title_right"
            ><SearchUserDate
              :title="titleYear"
              :type="'Histogram'"
              :introductionType="'staffId'"
              :yearIsShow="true"
              :list="dictData.yearList"
              :twoValue="sectionInfo.contractAmountPrincipal"
              v-model="sectionInfo.contractAmountYear"
              @change="onHistogramChange"
          /></span>
          <span class="title_right" v-if="isBoss"
            ><SearchUserDate
              :clearable="true"
              :filterable="true"
              :type="'Histogram'"
              :introductionType="'staffId'"
              :title="titlePrincipal"
              :list="userList"
              :yearValue="sectionInfo.contractAmountYear"
              v-model="sectionInfo.contractAmountPrincipal"
              @change="onHistogramChange"
          /></span>
        </div>
        <Histogram :contractAmountList="contractAmountList" />
      </el-card>
    </el-row> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    MoneyHistogram: () => import('./companyMoney/moneyHistogram.vue'),
    ProjectType: () => import('./projectType/projectType.vue'),
    ProjectStatus: () => import('./projectStatus/projectStatus.vue'),
    CapitalRisk: () => import('./capitalRisk/capitalRisk.vue'),
    SearchUserDate: () => import('./searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      dictData: {
        businessLines: [],
        yearList: [],
        companyType: [],
      },
      yearManageList: [], // 年度经营数据
      monthFinanceList: [], // 公司月度财务数据
      addBusinessList: [], // 季度新增商务数据表
      projectTypeList: [], // 项目类型分析
      projectStatusList: [], // 项目状态分析
      capitalRiskList: [], // 资金风险监控
      companyMoneyList: [], // 公司收支趋势图
      projectProfitList: [], // 项目利润趋势图
      sectionInfo: {
        // 条件筛选
        yearManageYear: null, // 年份
        monthFinanceYear: null,
        addBusinessYear: null,
        projectTypeYear: null,
        projectStatusYear: null,
        capitalRiskYear: null,
        companyMoneyYear: null,
        projectProfitYear: null,
        yearManageQuarter: '', // 季度
        monthFinanceMonth: null, // 月份
      },
      titleYear: '统计时间：',
      dataActive: 'yearManage',
    }
  },
  provide() {
    return {}
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    quarterGenerate() {
      const arr = []
      for (let index = 0; index < 4; index++) {
        arr.push({ id: index + 1, dictName: `第${index + 1}季度`, dictVal: `Q${index + 1}` })
      }
      arr.unshift({
        id: '0',
        dictName: '全部',
        dictVal: '',
      })
      return arr
    },
    monthGenerate() {
      const arr = []
      for (let index = 0; index < 12; index++) {
        arr.push({ id: index + 1, dictName: `${index + 1}月`, dictVal: `${index + 1}` })
      }
      return arr
    },
  },
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    init(val) {
      this.loading = true
      Promise.all([
        this.$api.statistics.queryAnnualExecutiveData(`date=${val}`),
        this.$api.statistics.queryMonthlyFinanceReport(`date=${val}`),
        this.$api.statistics.queryNewBusinessDataQuarterly(`date=${val}`),
      ])
        .then(res => {
          // 年度经营数据
          this.yearManageList = res[0].data.annualExecutiveDataVoList || []
          this.monthFinanceList = res[1].data.monthlyFinanceDataVoList || []
          // 由前端根据选择年份去过滤数据
          const arr = res[2].data.quarterlyBusinessDataVoList || []
          this.addBusinessList = arr.filter(v => v.year == val)
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    onYearManageChange(arr) {
      this.yearManageList = arr
    },
    onMonthFinanceChange(arr) {
      this.monthFinanceList = arr
    },
    onAddBusinessChange(arr) {
      this.addBusinessList = arr
    },
    onMoneyHistogramChange(arr) {
      this.companyMoneyList = arr
    },
    onProjectTypeChange(arr) {
      this.projectTypeList = arr
    },
    onProjectSourceChange(arr) {
      this.projectStatusList = arr
    },
    onCapitalRiskChange(arr) {
      this.capitalRiskList = arr
    },

    getMnthFinance(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else {
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        }
      })

      return sums
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else {
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        }
      })

      return sums
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.yearManageYear = dictVal
          this.sectionInfo.monthFinanceYear = dictVal
          this.sectionInfo.addBusinessYear = dictVal
          this.sectionInfo.projectTypeYear = dictVal
          this.sectionInfo.projectStatusYear = dictVal
          this.sectionInfo.capitalRiskYear = dictVal
          this.sectionInfo.companyMoneyYear = dictVal
          this.sectionInfo.projectMoneyYear = dictVal
          this.init(dictVal)
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  // height: 550px;
}
.business_individual {
  display: inline-block;
  text-align: center;
  width: 150px;
  font-size: 18px;
  .business_individual_title {
    font-size: 15px;
  }
  .business_individual_center {
    line-height: 35px;
    margin-bottom: 10px;
    font-weight: 900;
    color: #5c5b87;
  }
}
.text_Details_Bgc {
  //详情
  background-color: #409eff;
  color: #fff;
  padding: 5px 5px;
}
/deep/.el-card__header {
  border-bottom: 0px;
}
/deep/.el-tabs {
  .el-tabs__item {
    color: #000;
  }
}
</style>
